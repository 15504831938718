import { Box, Button, Divider, Typography } from "@mui/material";
import { useContext } from "react";
import { StateContext } from "../context";

function NotAvailable() {
  const { setPageState } = useContext(StateContext);
  return (
    <Box sx={{ textAlign: "center", margin: "auto" }}>
      <Typography variant="body1" textAlign="center" gutterBottom>
        <b>This code is currently in use on another device.</b>
      </Typography>
      <Typography variant="body1" textAlign="center" gutterBottom>
        Wait 30 minutes for the reservation to expire and try again with this
        code.
      </Typography>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        No time to waste? Copy paste the secret link from your other device to
        continue your reservation here!
      </Typography>
      <Button
        size="small"
        onClick={() => {
          window.location.href = "https://summit.turfnft.com";
        }}
      >
        Try a different code
      </Button>
    </Box>
  );
}

export default NotAvailable;
