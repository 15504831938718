import { Twitter } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Location } from "../../types";
import { StateContext } from "../context";
import { getLocation } from "../services";

function Success() {
  const { codeData } = useContext(StateContext);
  const [location, setLocation] = useState<Location>();

  useEffect(() => {
    if (!codeData) {
      return;
    }
    getLocation(codeData?.location).then((res) => setLocation(res));
  }, []);

  const tweet = `I just got this Cardano Summit 2022 NFT of ${codeData?.location} for free!%0a%0aClaim yours by joining the Summit today.%0a%0a`;
  return (
    <Box sx={{ textAlign: "center", margin: "auto" }}>
      <Typography variant="h1" gutterBottom>
        👏 Success 👏
      </Typography>
      <Typography variant="body1" gutterBottom>
        You'll receive the NFT soon.
      </Typography>
      <img
        src={`https://gw3.easy-ipfs.com/ipfs/${location?.artHash}`}
        width="150"
      />
      <Typography variant="body2" sx={{ marginTop: 3, marginBottom: 3 }}>
        In the mean time, please consider sharing your new NFT with the world 👇
      </Typography>
      <Button
        href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Fsummit.turfnft.com%2F&via=Turf_NFT&text=${tweet}&hashtags=CardanoSummit2022,SummitCNFT22,CNFT`}
        startIcon={<Twitter />}
        target="_blank"
      >
        Tweet about it
      </Button>
    </Box>
  );
}

export default Success;
