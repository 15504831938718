import { Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import { StateContext } from "../context";

function NotAvailable() {
  const { setPageState } = useContext(StateContext);
  return (
    <Box sx={{ textAlign: "center", margin: "auto" }}>
      <Typography variant="body1" textAlign="center">
        Woops. Something went wrong processing this code.
      </Typography>
      <Typography variant="body2" textAlign="center" sx={{ marginBottom: 4 }}>
        Try refreshing the page to try again or use a different browser. No
        luck? Contact us on Discord: discord.gg/qZNYMncHVJ
      </Typography>
      <Button
        size="large"
        onClick={() => {
          window.location.href = "https://summit.turfnft.com";
        }}
      >
        Try a different one
      </Button>
    </Box>
  );
}

export default NotAvailable;
