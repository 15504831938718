import { Box, Typography } from "@mui/material";

function Loading() {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: 500,
          height: 200,
        }}
      >
        <Typography
          textAlign="center"
          sx={{ zIndex: 1, textShadow: "0px 0px 5px black" }}
          variant="h1"
        >
          1% of minted NFTs is a rare Golden TURF. Get a free art print if yours
          is one of them!
        </Typography>
        <Box
          sx={{
            position: "absolute",
            width: 200,
            height: 200,
            animation: "grow 8s ease infinite",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/cardano.svg`}
            alt="Cardano logo"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Loading;
