import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  connectFirestoreEmulator,
  getFirestore,
  Timestamp,
} from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCQCpGOnicfQ2HwxvJq-Zzh0njt0nAarso",
  authDomain: "turf-cs22.firebaseapp.com",
  projectId: "turf-cs22",
  storageBucket: "turf-cs22.appspot.com",
  messagingSenderId: "493386417437",
  appId: "1:493386417437:web:df93fc1d5e75c05a718df4",
  measurementId: "G-5L158B4YQN",
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const db = getFirestore(app);

const functions = getFunctions(app);

export const createNftAndGetAddress = httpsCallable<
  { code: string },
  {
    paymentAddress: string;
    adaToSend: number;
    expires: Timestamp;
    reservation: string;
  }
>(functions, "createNftAndGetAddress");

export const getCodes = httpsCallable<
  { location: string; count: number; key: string },
  string[]
>(functions, "getCodes");

if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
}
