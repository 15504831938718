import { createContext, ReactNode, useEffect, useState } from "react";
import { Code } from "../types";
import { createNftAndGetAddress } from "./firebaseConfig";
import { getCodeData, getReservationData } from "./services";

type PageState =
  | "start"
  | "customInput"
  | "notAvailable"
  | "reserved"
  | "noCode"
  | "payment"
  | "success";

interface ExtendedCode extends Code {
  code: string | null;
}

const initialState = {
  pageState: "start" as PageState,
  setPageState: (state: PageState) => {},
  codeData: undefined as ExtendedCode | undefined,
  setCodeData: (state: ExtendedCode) => {},
  loading: false,
  setLoading: (loading: boolean) => {},
  message: "",
  setMessage: (message: string, timeout?: number) => {},
  code: "",
  setCode: (code: string) => {},
};

export const StateContext = createContext(initialState);

export const StateProvider = ({ children }: { children: ReactNode }) => {
  const [code, setCode] = useState(
    new URLSearchParams(window.location.search).get("code") || ""
  );
  const reservation = new URLSearchParams(window.location.search).get(
    "reservation"
  );
  const [pageState, setPageState] = useState<PageState>("start");
  const [codeData, setCodeData] = useState<ExtendedCode>();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (code === "closed") {
      return;
    }
    if (reservation && !codeData) {
      setLoading(true);
      getReservationData(reservation)
        .then((reservationData) => {
          if (!reservationData) {
            setPageState("notAvailable");
            return;
          }

          getCodeData(reservationData.code).then((codeData) => {
            if (!codeData) {
              setPageState("notAvailable");
              return;
            }
            if (codeData.status === "CLAIMED") {
              setCodeData({ ...codeData, code: reservationData.code });
              setPageState("success");
            } else if (codeData.status === "RESERVED") {
              setCodeData({ ...codeData, code: reservationData.code });
              setPageState("payment");
            } else {
              setLoading(true);
              createNftAndGetAddress({ code: reservationData.code })
                .then((res) => {
                  setCodeData({
                    ...codeData,
                    ...res.data,
                    code: reservationData.code,
                  });
                  setPageState("payment");
                  setLoading(false);
                })
                .catch(() => setPageState("notAvailable"));
            }
          });
        })
        .catch(() => {
          setMessage("Something went wrong, please contact us");
          setPageState("notAvailable");
        })
        .finally(() => setLoading(false));
    } else if (code && !codeData) {
      setLoading(true);
      getCodeData(code)
        .then((codeData) => {
          if (codeData && codeData.status === "FREE") {
            setPageState("start");
            setCodeData({ ...codeData, code });
          } else if (codeData && codeData.status === "RESERVED") {
            setPageState("reserved");
          } else {
            setPageState("notAvailable");
          }
        })
        .catch(() => {
          setMessage("Something went wrong, please contact us");
          setPageState("notAvailable");
        })
        .finally(() => setLoading(false));
    } else if (!code && !codeData) {
      setPageState("customInput");
    }
  }, [code, codeData, setCodeData, setLoading, setPageState, reservation]);

  return (
    <StateContext.Provider
      value={{
        pageState,
        setPageState,
        codeData,
        setCodeData,
        loading,
        setLoading,
        message,
        setMessage,
        code,
        setCode,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
