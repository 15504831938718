import { CopyAll } from "@mui/icons-material";
import { Box, Button, Divider, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useState } from "react";
import { wallets } from "../config";
import { StateContext } from "../context";
import { createTx } from "../services";

function Payment() {
  const { setMessage, codeData, setPageState } = useContext(StateContext);
  const [loadingWallet, setLoadingWallet] = useState(false);

  if (!codeData || !codeData.paymentAddress || !codeData.adaToSend) {
    return null;
  }

  const { paymentAddress, adaToSend } = codeData;
  const tryCreateWalletTx = async (walletName: keyof typeof wallets) => {
    try {
      setLoadingWallet(true);
      await createTx(walletName, paymentAddress, adaToSend || 0);
      setPageState("success");
    } catch (e: any) {
      console.log(e);
      setMessage(e.message || e.info || JSON.stringify(e));
    } finally {
      setLoadingWallet(false);
    }
  };

  const hasWallet =
    window.cardano &&
    Object.keys(wallets).some((wallet) => Boolean(window.cardano[wallet]));

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
      }}
    >
      {hasWallet ? (
        <>
          <Typography variant="body1" textAlign="center">
            Select your wallet to mint
          </Typography>
          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}
          >
            {Object.keys(wallets).map(
              (wallet) =>
                Boolean(window.cardano[wallet]) && (
                  <Button
                    key={wallet}
                    size="small"
                    disabled={loadingWallet}
                    sx={{ fontSize: 14 }}
                    onClick={() =>
                      tryCreateWalletTx(wallet as keyof typeof wallets)
                    }
                  >
                    {wallets[wallet as keyof typeof wallets]}
                  </Button>
                )
            )}
          </Stack>
          <Typography variant="body2" textAlign="center">
            Don't see your wallet? Please enable it and refresh the page.
          </Typography>
          <Divider sx={{ width: "100%", marginTop: 2, marginBottom: 2 }} />
        </>
      ) : (
        <>
          <Typography variant="body2" textAlign="center">
            No wallet detected. If you want to use an integration please enable
            your wallet and refresh the page.
          </Typography>
          <Divider sx={{ width: "100%", marginTop: 2, marginBottom: 2 }} />
        </>
      )}
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="body1" gutterBottom>
          {hasWallet ? "or" : "Please"} send <b>{codeData.adaToSend}</b> ADA to
          the following address within 30 minutes:
        </Typography>
        <img
          width="100"
          height="100"
          src={`https://chart.googleapis.com/chart?chs=150x150&chld=H|0&cht=qr&choe=UTF-8&chl=${paymentAddress}`}
          alt="Wallet adress qr code"
        />
        <Typography
          variant="body2"
          sx={{ overflowWrap: "anywhere", cursor: "pointer" }}
          onClick={async () => {
            await navigator.clipboard.writeText(paymentAddress);
            setMessage("Address copied to clipboard!");
          }}
        >
          {paymentAddress}{" "}
          <CopyAll fontSize="small" sx={{ marginBottom: "-4px" }} />
        </Typography>
        <Divider sx={{ width: "100%", marginTop: 2, marginBottom: 2 }} />
        <Typography variant="body2" textAlign="center">
          <i>
            Important: do not send ADA from an exchange wallet - always use a
            self hosted wallet
          </i>
        </Typography>
        <Button
          sx={{ marginTop: 4, marginBottom: 1, fontSize: 14 }}
          size="small"
          color="secondary"
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:?subject=${encodeURIComponent(
            "Mint the Cardano Summit 2022 NFTs!"
          )}&body=${encodeURIComponent(
            "To mint your Cardano Summit 2022 NFT visit your personal minting link: "
          )}${encodeURIComponent(window.location.href)}${encodeURIComponent(
            "\n\nThis link is valid until Tuesday so don't wait too long to mint!\n\nQuestions? Join our Discord: discord.gg/qZNYMncHVJ"
          )}`}
        >
          Continue minting on another device
        </Button>
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          Questions? Join our Discord:{" "}
          <Link href="https://discord.com/invite/qZNYMncHVJ" target="_blank">
            discord.gg/qZNYMncHVJ
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default Payment;
