import { chunk } from "lodash";
import { useState } from "react";
import { getCodes } from "../firebaseConfig";

function LinkListItem({
  index,
  code,
  location,
}: {
  index: number;
  code: string;
  location: string;
}) {
  const url = "https://summit.turfnft.com";
  return (
    <div
      style={{
        width: "calc(50% - 2em - 1px)",
        position: "relative",
        overflow: "hidden",
        padding: "1em",
        borderBottom: "1px dashed silver",
        borderRight: index % 2 === 0 ? "1px dashed silver" : "",
      }}
    >
      <div
        style={{
          fontSize: "0.5em",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/map_preview.svg`}
          style={{
            maxWidth: "200%",
            opacity: "0.1",
            filter: "grayscale(100%)",
            position: "absolute",
            zIndex: -1,
          }}
        />
        <div
          style={{
            padding: "3em",
            // backgroundImage: `url(${process.env.PUBLIC_URL}/flyer_bg.png)`,
            backgroundSize: "cover",
            color: "black",
            WebkitPrintColorAdjust: "exact",
            display: "flex",
            height: 470,
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontFamily: "Space Mono",
              fontSize: "2em",
              textAlign: "center",
            }}
          >
            <b>Claim your exclusive Cardano Summit NFT!</b>
          </div>

          <div
            style={{
              fontFamily: "Space Mono",
              fontSize: "2em",
              textAlign: "center",
              fontWeight: "bold",
              marginTop: "1em",
            }}
          >
            {location}
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "4em",
              marginBottom: "3em",
            }}
          >
            <div style={{ fontSize: "2em" }}>
              Go to <u>summit.turfnft.com</u> and enter
              <br /> <b>{code}</b>
            </div>
            <div>
              <img
                src={`https://chart.googleapis.com/chart?chl=${encodeURIComponent(
                  url + "?code=" + code
                )}&chs=75x75&chld=H|0&cht=qr&choe=UTF-8`}
                alt="Cardano Summit 2022 NFT claim code"
                style={{
                  marginTop: "1em",
                }}
              />
            </div>
            <div style={{ marginTop: "1em", fontSize: "1.5em" }}>
              1% chance to mint a gold NFT that grants you a HQ Turf print
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "auto",
            }}
          >
            <img
              width="150"
              src={`${process.env.PUBLIC_URL}/cs22logo_alt_star_b.svg`}
              alt="Cardano logo"
            />
            <div
              style={{
                fontFamily: "Bebas Neue",
                fontSize: "3.5em",
                color: "#13152f",
              }}
            >
              TURF
            </div>
          </div>
          <div
            style={{
              textAlign: "right",
              color: "black",
              marginTop: "1em",
            }}
          >
            <div>Transaction fees apply</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function LinkList() {
  const params = new URLSearchParams(window.location.search);
  const key = params.get("key") || "";
  const [pages, setPages] = useState<string[][]>([]);
  const [location, setLocation] = useState("");
  const [count, setCount] = useState(10);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  if (pages.length === 0) {
    return (
      <div style={{ margin: "1em" }}>
        <input
          placeholder="location"
          onChange={(e) => setLocation(e.target.value)}
        />
        <br />
        <input
          placeholder="count"
          onChange={(e) => setCount(parseInt(e.target.value))}
        />
        <br />
        <br />
        <button
          onClick={() => {
            setLoading(true);
            getCodes({ location, count, key })
              .then((res) => setPages(chunk(res.data, 4)))
              .catch((e) => setError(e.message))
              .finally(() => setLoading(false));
          }}
          disabled={loading}
        >
          Get QR codes
        </button>
        {Boolean(error) && <div style={{ color: "red" }}>error: {error}</div>}
      </div>
    );
  }

  return (
    <>
      {pages.map((page, i) => (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            fontFamily: "Helvetica",
          }}
          key={i}
        >
          {page.map((code, j) => (
            <LinkListItem
              index={j}
              code={String(code)}
              key={code}
              location={location}
            />
          ))}
          <div style={{ breakAfter: "always" }} />
        </div>
      ))}
    </>
  );
}

export default LinkList;
