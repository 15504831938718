export const NFT_PRICE = 3.5;
// export const BLOCKFROST_URL = "https://cardano-testnet.blockfrost.io/api/v0";
// export const BLOCKFROST_PROJECT_ID = "testnetOSsEYmp1mLMdy9E1FU7e0p96H8FAGUP0";
// export const BLOCKFROST_NETWORK = "Testnet";
export const BLOCKFROST_URL = "https://cardano-mainnet.blockfrost.io/api/v0";
export const BLOCKFROST_PROJECT_ID = "mainnettHWPLE2xqHL7vGdOtzSe5R4xhwiw7MI2";
export const BLOCKFROST_NETWORK = "Mainnet";

export enum wallets {
  "nami" = "Nami",
  "yoroi" = "Yoroi",
  "eternl" = "Eternl",
  "flint" = "Flint",
  "typhoncip30" = "Typhon",
  "nufi" = "Nufi",
  "gerowallet" = "Gero",
}
