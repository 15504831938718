import { Box, Button, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Location } from "../../types";
import { StateContext } from "../context";
import { createNftAndGetAddress } from "../firebaseConfig";
import { getLocation } from "../services";

function Start() {
  const { setPageState, setLoading, setMessage, codeData, setCodeData } =
    useContext(StateContext);

  const [location, setLocation] = useState<Location>();

  useEffect(() => {
    if (!codeData) {
      return;
    }
    getLocation(codeData?.location).then((res) => setLocation(res));
  }, []);

  if (!codeData) {
    return null;
  }

  const reserveNft = async () => {
    setLoading(true);
    try {
      const res = await createNftAndGetAddress({ code: codeData?.code || "" });
      setCodeData({ ...codeData, ...res.data });
      setPageState("payment");
      // @ts-ignore
      const url = new URL(window.location);
      url.searchParams.set("reservation", res.data.reservation);
      window.history.pushState({}, "", url);
    } catch (e) {
      console.log(e);
      setPageState("notAvailable");
      setMessage("Could not reserve your NFT. Please contact us.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
      }}
    >
      <Typography variant="h1" textAlign="center">
        Congratulations, your {codeData?.location} NFT is available!
      </Typography>
      <Box sx={{ width: 200, marginBottom: 1 }}>
        <img src={`https://gw3.easy-ipfs.com/ipfs/${location?.gifHash}`} />
      </Box>
      <Button
        size="large"
        onClick={reserveNft}
        sx={{ animation: "jump 0.5s ease" }}
      >
        Claim it now
      </Button>
    </Box>
  );
}

export default Start;
