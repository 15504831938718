import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { Blockfrost, Lucid } from "lucid-cardano";
import { useState } from "react";
import { Code, Location, Reservation } from "../types";
import {
  BLOCKFROST_NETWORK,
  BLOCKFROST_PROJECT_ID,
  BLOCKFROST_URL,
  wallets,
} from "./config";
import { db } from "./firebaseConfig";

export const useLocations = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const locationsRef = collection(db, "locations");
  getDocs(locationsRef).then((res) => {
    setLocations(res.docs.map((d) => d.data() as Location));
  });

  return locations;
};

export const getCodeData = async (code: string) => {
  const ref = doc(db, "codes", code);
  const snap = await getDoc(ref);

  if (snap.exists()) {
    return snap.data() as Code;
  }
};

export const getReservationData = async (reservation: string) => {
  const ref = doc(db, "reservations", reservation);
  const snap = await getDoc(ref);

  if (snap.exists()) {
    return snap.data() as Reservation;
  }
};

export const getLocation = async (name: string) => {
  const ref = doc(db, "locations", name);
  const snap = await getDoc(ref);

  if (snap.exists()) {
    return snap.data() as Location;
  }
};

export const createTx = async (
  provider: keyof typeof wallets,
  address: string,
  ada: number
) => {
  const lucid = await Lucid.new(
    new Blockfrost(BLOCKFROST_URL, BLOCKFROST_PROJECT_ID),
    BLOCKFROST_NETWORK
  );

  const wallet = window.cardano[provider];
  const api = await wallet.enable();

  lucid.selectWallet(api);

  const tx = await lucid
    .newTx()
    .payToAddress(address, { lovelace: BigInt(ada * 1000000) })
    .complete();

  const signedTx = await tx.sign().complete();

  const txHash = await signedTx.submit();

  console.log(txHash);
};
