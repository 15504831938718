import "normalize.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import LinkList from "./components/LinkList";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { StateProvider } from "./context";
import "./firebaseConfig";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://291c8b3daddd42e5b0dda641785784d9@o4504185188909056.ingest.sentry.io/4504185190481920",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <StateProvider>
      <App />
      {/* <LinkList /> */}
    </StateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
