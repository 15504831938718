import { Box, Typography } from "@mui/material";

function Closed() {
  return (
    <Box sx={{ textAlign: "center", margin: "auto" }}>
      <Typography variant="body1" textAlign="center">
        Minting Summit NFTs is now closed.
      </Typography>
      <Typography variant="body2" textAlign="center" sx={{ marginBottom: 4 }}>
        Thanks for your interest!
      </Typography>
    </Box>
  );
}

export default Closed;
