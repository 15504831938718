import { Button, Link, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useState } from "react";
import { StateContext } from "../context";

function CustomInput() {
  const { setCode } = useContext(StateContext);
  const [inputCode, setInputCode] = useState("");

  return (
    <Box sx={{ textAlign: "center", margin: "auto" }}>
      <Typography variant="h1">
        Welcome to the Cardano Summit NFT minting page!
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        To claim the NFT, simply enter your personal code.
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setCode(inputCode);
        }}
      >
        <TextField
          onChange={(e) => setInputCode(e.currentTarget.value)}
          label="Enter your code"
          variant="filled"
          InputProps={{
            endAdornment: <Button type="submit">Go!</Button>,
          }}
        />
      </form>

      <Typography variant="body2" sx={{ marginTop: 2 }}>
        No code? You can join a{" "}
        <Link
          href="https://summit.cardano.org/community-led-events-locations/"
          target="_blank"
        >
          community event
        </Link>{" "}
        or{" "}
        <Link href="https://summit.cardano.org/" target="_blank">
          attend virtually
        </Link>{" "}
        to get one 👀
      </Typography>
    </Box>
  );
}

export default CustomInput;
